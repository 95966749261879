import { properties } from './types'

const INITIAL_STATE = {
  
  is_getting_listing: false,
  is_creating_property: false,
	is_getting_units: false,
	is_getting_tenants: false,
	is_getting_leases: false,
	is_registering_tenant:false,
	error: null,
	property: {},
	propertyList: [],
	propertyUnits: [],
	propertyTenants: [],
	leasesList: [],
	tenant: {}

};

export const propertyReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {

		case properties.GET_PROPERTIES_STARTED:
			return { ...state, is_getting_listing:true };

		case properties.GET_PROPERTIES_SUCCESSFUL:
			return { ...state, propertyList: action.payload , is_getting_listing:false};

		case properties.GET_PROPERTIES_FAILED:
			return { ...state, error: action.payload, is_getting_listing:false};

		case properties.REGISTER_PROPERTY_STARTED:
			return { ...state, is_creating_property:true };

		case properties.REGISTER_PROPERTY_SUCCESSFUL:
			return { ...state , is_creating_property:false, property: action.payload};

		case properties.REGISTER_PROPERTY_FAILED:
			return { ...state, error: action.payload, is_creating_property:false};

		case properties.GET_UNITS_STARTED:
			return { ...state, is_getting_units:true };

		case properties.GET_UNITS_SUCCESSFUL:
			return { ...state, propertyUnits: action.payload , is_getting_units:false};

		case properties.GET_UNITS_FAILED:
			return { ...state, error: action.payload, is_getting_units:false};
	
		case properties.REGISTER_TENANT_STARTED:
			return { ...state, is_registering_tenant:true };

		case properties.REGISTER_TENANT_SUCCESSFUL:
			return { ...state , is_registering_tenant:false, tenant: action.payload};

		case properties.REGISTER_TENANT_FAILED:
			return { ...state, error: action.payload, is_registering_tenant:false};
			
		case properties.GET_TENANTS_STARTED:
			return { ...state, is_getting_tenants:true };

		case properties.GET_TENANTS_SUCCESSFUL:
			return { ...state, propertyTenants: action.payload , is_getting_tenants:false};

		case properties.GET_TENANTS_FAILED:
			return { ...state, error: action.payload, is_getting_tenants:false};

		case properties.GET_LEASES_STARTED:
			return { ...state, is_getting_leases:true };

		case properties.GET_LEASES_SUCCESSFUL:
			return { ...state, leasesList: action.payload , is_getting_leases:false};

		case properties.GET_LEASES_FAILED:
			return { ...state, error: action.payload, is_getting_leases:false};

		default:
			return state;
	}
};
