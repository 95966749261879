import { user } from './types'

const INITIAL_STATE = {
	data: {	roles:[], user: {Wallet:{balance: 0, wallet:0}}},
	newUser: null,
  isLoggingin: false,
	loggedIn: false,
	creatingUser: false,
	updatingProfile: false,
	user: {},
	error: null,
	users: [],
	accessToken: '',
	session_expired: false,

};

export const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case user.LOGOUT:

			return { ...INITIAL_STATE };

		case user.LOGIN_STARTED:
			return { ...state, isLoggingin:true };

		case user.LOGIN_SUCCESSFUL:
			return { ...state, data: action.payload , isLoggingin:true, loggedIn:true };

		case user.LOGIN_FAILED:
			return { ...state, error: action.payload, isLoggingin:false, loggedIn: false  };

		case user.SIGNUP_STARTED:
			return { ...state, creatingUser:true };

		case user.SIGNUP_SUCCESSFUL:
			return { ...state, newUser: action.payload , creatingUser:false};

		case user.SIGNUP_FAILED:
			return { ...state, error: action.payload, creatingUser:false  };

		case user.SESSION_EXPIRED:
			return {
				...state,
				session_expired: action.payload.session_expired
			}
		case user.UPDATE_PROFILE_STARTED :
			return {
				...state, updatingProfile:true
			}

		case user.UPDATE_PROFILE_SUCCESSFUL:
			return {
				...state, user: action.payload, updatingProfile:false
			}
		
		case user.UPDATE_PROFILE_FAILED:
			return {
				...state, updatingProfile:false
			}

		default:
			return state;
	}
};
