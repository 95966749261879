import { combineReducers } from 'redux';


import {userReducer} from '../includes/user/reducer';
import { propertyReducer } from '../includes/property/reducers';
const rootReducer = combineReducers({

    user: userReducer,
    property: propertyReducer
});

export default rootReducer;