export const properties = {
  REGISTER_PROPERTY_STARTED : 'REGISTER_PROPERTY_STARTED',
  REGISTER_PROPERTY_SUCCESSFUL : 'REGISTER_PROPERTY_SUCCESSFUL',
  REGISTER_PROPERTY_FAILED : 'REGISTER_PROPERTY_FAILED',

  GET_PROPERTIES_STARTED : 'GET_PROPERTIES_STARTED',
  GET_PROPERTIES_SUCCESSFUL : 'GET_PROPERTIES_SUCCESSFUL',
  GET_PROPERTIES_FAILED : 'GET_PROPERTIES_FAILED',

  GET_UNITS_STARTED : 'GET_UNITS_STARTED',
  GET_UNITS_SUCCESSFUL : 'GET_UNITS_SUCCESSFUL',
  GET_UNITS_FAILED : 'GET_UNITS_FAILED',

  GET_TENANTS_STARTED : 'GET_TENANTS_STARTED',
  GET_TENANTS_SUCCESSFUL : 'GET_TENANTS_SUCCESSFUL',
  GET_TENANTS_FAILED : 'GET_TENANTS_FAILED',

  GET_LEASES_STARTED : 'GET_LEASES_STARTED',
  GET_LEASES_SUCCESSFUL : 'GET_LEASES_SUCCESSFUL',
  GET_LEASES_FAILED : 'GET_LEASES_FAILED',

  GET_DASHBOARD_STATS_STARTED : 'GET_DASHBOARD_STATS_STARTED',
  GET_DASHBOARD_STATS_SUCCESSFUL : 'GET_DASHBOARD_STATS_SUCCESSFUL',
  GET_DASHBOARD_STATS_FAILED : 'GET_DASHBOARD_STATS_FAILED',

  REGISTER_TENANT_STARTED : 'REGISTER_TENANT_STARTED',
  REGISTER_TENANT_SUCCESSFUL : 'REGISTER_TENANT_SUCCESSFUL',
  REGISTER_TENANT_FAILED : 'REGISTER_TENANT_FAILED',

}