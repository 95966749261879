export const user = {
  LOGIN_STARTED : 'LOGIN_STARTED',
  LOGIN_SUCCESSFUL : 'LOGIN_SUCCESSFUL',
  LOGIN_FAILED : 'LOGIN_FAILED',

  SIGNUP_STARTED : 'SIGNUP_STARTED',
  SIGNUP_SUCCESSFUL : 'SIGNUP_SUCCESSFUL',
  SIGNUP_FAILED : 'SIGNUP_FAILED',

  SESSION_EXPIRED : 'SESSION_EXPIRED',

  IS_REFRESHING_PROFILE : 'IS_REFRESHING_PROFILE',
  REFRESHING_PROFILE_SUCCESSFUL : 'REFRESHING_PROFILE_SUCCESSFUL',
  REFRESHING_PROFILE_FAILED : 'REFRESHING_PROFILE_FAILED',

  UPDATE_PROFILE_STARTED : 'LOGIN_STARTED',
  UPDATE_PROFILE_SUCCESSFUL : 'UPDATE_PROFILE_SUCCESSFUL',
  UPDATE_PROFILE_FAILED : 'LOGIN_FAILED',
}